import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Select, Row, Col, Typography, Modal, Spin, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import appicon from "../assets/images/appiconandroid.jpg";
import axios from "axios";
import { REACT_APP_API_URL } from "../env";
import { IoPeopleSharp, IoFitnessSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkStatus, load_user, logout, resetStatus, updateProfile } from "../redux/actions/auth";
import CustomSelect from "../components/common/DropDown";
import runningman from "../assets/images/logowhite.png";
import PhoneInput from "react-phone-input-2";

const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;

const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();
  const { freeTrial } = useSelector((state) => state.payment);
  const { user, isAuthenticated, Theme, signUpStatus } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [otherText, setOtherText] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(50);
  const [price, setPrice] = useState("$75.00");
  const [planId, setPlanId] = useState("tier2");
  const [activeTab, setActiveTab] = useState("1");
  const [phone, setPhone] = useState(user?.profile?.phone_number || "");
  const [country, setCountry] = useState("us");
  const [profession, setProfession] = useState(
    user?.profile?.profession || "Strength & Conditioning Coach"
  );
  const [errors, setErrors] = useState({});
  const [client_label, setclient_label] = useState(
    user?.profile?.client_label || "Client"
  );
  const fetchUser = () => dispatch(load_user());

  const handleOnChangePhone = (value, data) => {
    setPhone(value);
    setCountry(data.countryCode);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    dispatch(checkStatus);
    fetchUser();
  }, []);

   useEffect(() => {
     if (!isAuthenticated && signUpStatus !== 201) {
       history.push("/sign-in");
     }
   if(isAuthenticated && signUpStatus === 201){   dispatch(resetStatus());}
   }, [isAuthenticated]);

  const goHome = () => {
    setModalShow(false);
    history.push("/");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(false);

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (paymentMethod) {
        const paymentMethodId = paymentMethod.id;
        const token = await localStorage.getItem("access");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + token
          }
        };

        try {
          const response = await axios.post(
            `${REACT_APP_API_URL}/api/stripepayment/`,
            {
              payment_method_id: paymentMethodId,
              plan_id: planId
            },
            config
          );

          if (response.data) {
            dispatch(checkStatus);
            history.push("/");
          }
        } catch (err) {
          setError(true);
          console.error("Error confirming payment on the server:", err);
        }
      } else {
        setError(true);
        console.error("PaymentMethod is undefined.");
      }

      if (phoneError) {
        setPhoneError("");
      }
      try {
        const phone_number = `+${phone}`;
        let measurement = "Metric";
        if (country === "mm" || country === "lr" || country === "us") {
          measurement = "Imperial";
        }
        if (profession === "Other" && !otherText) {
          return;
        }

        dispatch(
          updateProfile(user?.profile?.id, { phone_number, profession, measurement, client_label })
        );
      } catch (error) {
        if (phone.length < 8) {
          setPhoneError("Please enter phone number");
        }
        setLoading(false);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

   const handleLogout = () => {
     dispatch(logout());
     history.push("/sign-in");
   };
  return (
    <Col
      className={`fade-in-slide-up-element ${
        true ? "fade-in-slide-up-animation" : ""
      }`}
      align={"middle"}
      justify={"center"}
      style={{
        position: "relative",
        borderRadius: 10,
        boxShadow: "2px 5px 1px 1px rgba(0, 0, 0, 0.1)",
        backgroundImage: `url(${require("../assets/images/subscribe.png")})`, // Replace with your actual image path
        backgroundSize: "cover", // Make sure the background covers the entire element
        backgroundPosition: "center", // Center the background image
        backgroundRepeat: "no-repeat",
        marginRight: isMobile ? "2.5%" : null
      }}
    >
      <span
        style={{
          cursor: "pointer",
          fontSize: isMobile ? "3vw": ".7vw",
          position: "absolute",
          right: "5vw",
          top: isMobile ? null : "1vw",
          color: Theme.WHITE,
          zIndex: 999
        }}
        onClick={handleLogout}
      >
        Log Out
      </span>
      <Col align="middle" justify="center">
        <img
          style={{
            height: isMobile ? "5vw" : "3vw",
            position: "relative",
            top: "2vw",
            marginBottom: isMobile ? null : ".85vw"
          }}
          className={`fade-in-slide-up-element ${
            true ? "fade-in-slide-up-animation" : ""
          }`}
          src={runningman}
          alt=""
        />
        {freeTrial ? (
          <h1
            style={{
              fontSize: isMobile ? "5vw" : "1.25vw",
              color: "white",
              marginBottom: isMobile ? "2vh" : ".85vw"
            }}
          >
            Start Your Free 14-Day Trial
          </h1>
        ) : (
          <h1
            style={{
              fontSize: isMobile ? "5vw" : "1.25vw",
              color: "white",
              marginBottom: isMobile ? "2vh" : ".85vw",
              width: isMobile ? "75%" : null
            }}
          >
            Welcome Back. Choose Your Subscription Level.
          </h1>
        )}
      </Col>
      <Tabs
        style={{
          width: isMobile ? "95%" : "35vw",
          height: isMobile ? "100vh" : "65vh",
          marginTop: "1vw",
          background: "#1A1E23",
          position: "relative",
          borderRadius: 20,
          margin: "0 auto",
          opacity: 0.9
        }}
        centered
        tabBarStyle={{
          textAlign: "center",
          color: "#C04848",
          textDecoration: "none"
        }}
        activeKey={activeTab}
        onChange={setActiveTab}
        type="card"
      >
        <TabPane
          tab="Profile"
          key="1"
          style={{ marginTop: isMobile ? "5vw" : ".5vw", opacity: 1 }}
        >
          <h7
            style={{
              fontSize: isMobile ? "5vw" : "1.25vw",
              color: "white",
              marginBottom: "2.5%",

              fontWeight: "bold"
            }}
          >
            Profile Information
          </h7>
          <Col style={{ width: "100%", height: "50vw" }}>
            <div
              style={{
                marginTop: isMobile ? "5%" : "2.5%",
                marginBottom: isMobile ? "10%" : "5%",
                width: isMobile ? "75%" : "35%"
              }}
            >
              <h7
                style={{
                  fontSize: isMobile ? "4vw" : "1vw",
                  color: "white",
                  marginBottom: "5%"
                }}
              >
                Phone Number
              </h7>

              <PhoneInput
                country={"us"}
                value={phone}
                onChange={handleOnChangePhone}
                placeholder="Phone"
                style={{ marginTop: "5%" }}
                inputStyle={{
                  borderRadius: 10,
                  backgroundColor: "#efefef",
                  width: "100%",
                  // height: "70%",
                  position: "relative",
                  fontSize: isMobile ? "4vw" : ".85vw"

                  // padding:"3%"
                }}
              />
              {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
            </div>
            <h7 style={{ fontSize: isMobile ? "4vw" : "1vw", color: "white" }}>
              What's your Profession?
            </h7>
            <Col style={{ marginBottom: isMobile ? "10%" : "5%" }}>
              <div style={{ marginTop: "2.5%" }}>
                <CustomSelect
                  defaultValue={profession}
                  options={[
                    {
                      value: "Strength & Conditioning Coach",
                      label: "Strength & Conditioning Coach"
                    },
                    { value: "Athletic Trainer", label: "Athletic Trainer" },
                    {
                      value: "Physical Therapist",
                      label: "Physical Therapist"
                    },
                    { value: "Chiropractor", label: "Chiropractor" },
                    { value: "Sports Scientist", label: "Sports Scientist" },
                    { value: "Other", label: "Other" }
                  ]}
                  onChange={(value) => setProfession(value)}
                  width={isMobile ? "75%" : "15vw"}
                  placeholder="Select an option"
                  lightModeBackground="#f1f1fb"
                  darkModeBackground="#000"
                />

                {profession === "Other" && (
                  <div>
                    <input
                      type="text"
                      value={otherText}
                      onChange={(e) => setOtherText(e.target.value)}
                      placeholder="Other Profession"
                      style={{
                        marginTop: "2.5%",
                        borderRadius: 10,
                        backgroundColor: "#efefef",
                        width: isMobile ? "75%" : "100%",
                        position: "relative"
                      }}
                    />
                    {errors.otherText && (
                      <p style={{ color: "red" }}>{errors.otherText}</p>
                    )}
                  </div>
                )}
              </div>
            </Col>

            <h7 style={{ fontSize: isMobile ? "4vw" : "1vw", color: "white" }}>
              What do we call your Clients?
            </h7>
            <div style={{ marginTop: "2.5%" }}>
              <CustomSelect
                defaultValue={client_label}
                options={[
                  {
                    value: "Client",
                    label: "Clients"
                  },
                  { value: "Athlete", label: "Athletes" },
                  {
                    value: "Patient",
                    label: "Patients"
                  },
                  { value: "Student", label: "Students" },
                  { value: "Minion", label: "Minions" }
                ]}
                onChange={(value) => setclient_label(value)}
                width={isMobile ? "75%" : "15vw"}
                placeholder="Select an option"
                lightModeBackground="#f1f1fb"
                darkModeBackground="#000"
              />
            </div>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginTop: isMobile ? "5vh" : "2vw",
                width: "35%",
                fontSize: isMobile ? "4vw" : ".75vw"
              }}
              onClick={() => setActiveTab("2")}
            >
              NEXT
            </Button>
          </Col>
        </TabPane>
        <TabPane
          tab="Package"
          key="2"
          tabBarStyle={{
            background: "black"
          }}
        >
          <div style={{ marginTop: isMobile ? "5vw" : null }}>
            <Text
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: isMobile ? "5vw" : "1.25vw"
              }}
            >
              Select Subscription Level
            </Text>
            {!isMobile && (
              <Col style={{ width: "75%", marginTop: "1%" }}>
                <Text
                  style={{
                    color: "white",
                    // fontWeight: "bold",
                    fontSize: isMobile ? "2vw" : ".65vw"
                  }}
                >
                  All subscription levels come with full access to our web and
                  mobile app including unlimited assessments, workouts and all
                  other additional features
                </Text>
              </Col>
            )}
          </div>
          <Col
            align="middle"
            justify="center"
            style={{ width: "100%", marginTop: "1vw" }}
          >
            <Col style={{ marginTop: "1vw" }}>
              <Row align="middle" justify="center">
                <Col
                  onClick={() => {
                    setPrice("$30.00");
                    setPlanId("tier1");
                    setSelectedAmount(15);
                  }}
                  style={{
                    border:
                      planId === "tier1" ? `1px solid ${Theme.WHITE}` : null,
                    backgroundColor: "black",
                    opacity: 0.8,
                    boxShadow:
                      planId === "tier1"
                        ? "2px 2px 5px 5px rgba(255, 255, 255, 0.1)"
                        : null,
                    borderRadius: 10,
                    padding: isMobile ? "2.5%" : "5%",
                    width: isMobile ? "75%" : "10vw",
                    cursor: "pointer",
                    marginTop: isMobile ? "2vh" : null
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "4vw" : ".95vw",
                      width: "100%",
                      color: Theme.PRIMARY,
                      marginBottom: ".5vw",
                      position: "relative",
                      bottom: ".5vw"
                    }}
                  >
                    Core
                  </Text>
                  <Col>
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: isMobile ? "6vw" : "2vw",
                        position: "relative",
                        bottom: ".35vw"
                      }}
                    >
                      $30{" "}
                    </Text>
                    {!isMobile && (
                      <Col>
                        <Text
                          style={{
                            color: "white",
                            // fontWeight: "bold",
                            fontSize: isMobile ? "3vw" : ".75vw",
                            position: "relative",
                            bottom: ".5vw"
                          }}
                        >
                          per month
                        </Text>
                      </Col>
                    )}
                    <Col>
                      <Text
                        style={{
                          color: "white",
                          top: ".25vw",
                          position: "relative",

                          fontSize: isMobile ? "3.5vw" : ".75vw",
                          color: Theme.PRIMARY,
                          fontWeight: "bold"
                        }}
                      >
                        15 {client_label}s
                      </Text>
                    </Col>
                  </Col>
                </Col>
                <Col
                  onClick={() => {
                    setPrice("$75.00");
                    setPlanId("tier2");
                    setSelectedAmount(50);
                  }}
                  style={{
                    border:
                      planId === "tier2" ? `1px solid ${Theme.WHITE}` : null,
                    backgroundColor: "black",
                    opacity: 0.8,
                    boxShadow:
                      planId === "tier2"
                        ? "2px 2px 5px 5px rgba(255, 255, 255, 0.1)"
                        : null,
                    borderRadius: 10,
                    padding: isMobile ? "2.5%" : "5%",
                    width: isMobile ? "75%" : "10vw",
                    cursor: "pointer",
                    marginTop: isMobile ? "2vh" : null,
                    marginLeft: "1vw",
                    marginRight: "1vw"
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "4vw" : ".95vw",
                      width: "100%",
                      color: Theme.PRIMARY,
                      marginBottom: ".5vw",
                      position: "relative",
                      bottom: ".5vw"
                    }}
                  >
                    Performance
                  </Text>
                  <Col>
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: isMobile ? "6vw" : "2vw",
                        position: "relative",
                        bottom: ".35vw"
                      }}
                    >
                      $75{" "}
                    </Text>
                    {!isMobile && (
                      <Col>
                        <Text
                          style={{
                            color: "white",
                            // fontWeight: "bold",
                            fontSize: isMobile ? "3vw" : ".75vw",
                            position: "relative",
                            bottom: ".5vw"
                          }}
                        >
                          per month
                        </Text>
                      </Col>
                    )}
                    <Col>
                      <Text
                        style={{
                          color: "white",
                          top: ".25vw",
                          position: "relative",

                          fontSize: isMobile ? "3.5vw" : ".75vw",
                          color: Theme.PRIMARY,
                          fontWeight: "bold"
                        }}
                      >
                        50 {client_label}s
                      </Text>
                    </Col>
                  </Col>
                </Col>
                <Col
                  onClick={() => {
                    setPrice("$125.00");
                    setPlanId("tier3");
                    setSelectedAmount(100);
                  }}
                  style={{
                    border:
                      planId === "tier3" ? `1px solid ${Theme.WHITE}` : null,
                    backgroundColor: "black",
                    opacity: 0.8,
                    boxShadow:
                      planId === "tier3"
                        ? "2px 2px 5px 5px rgba(255, 255, 255, 0.1)"
                        : null,

                    borderRadius: 10,
                    padding: isMobile ? "2.5%" : "5%",
                    width: isMobile ? "75%" : "10vw",
                    cursor: "pointer",
                    marginTop: isMobile ? "2vh" : null
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "4vw" : ".95vw",
                      width: "100%",
                      color: Theme.PRIMARY,
                      marginBottom: ".5vw",
                      position: "relative",
                      bottom: ".5vw"
                    }}
                  >
                    Elite
                  </Text>
                  <Col>
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: isMobile ? "6vw" : "2vw",
                        position: "relative",
                        bottom: ".35vw"
                      }}
                    >
                      $125 <br />
                    </Text>
                    {!isMobile && (
                      <Col>
                        <Text
                          style={{
                            color: "white",
                            // fontWeight: "bold",
                            fontSize: isMobile ? "3vw" : ".75vw",
                            position: "relative",
                            bottom: ".5vw"
                          }}
                        >
                          per month
                        </Text>
                      </Col>
                    )}
                    <Col>
                      <Text
                        style={{
                          color: "white",
                          top: ".25vw",
                          position: "relative",

                          fontSize: isMobile ? "3.5vw" : ".75vw",
                          color: Theme.PRIMARY,
                          fontWeight: "bold"
                        }}
                      >
                        100 {client_label}s
                      </Text>
                    </Col>
                  </Col>
                </Col>
              </Row>
              <Col style={{ width: "75%", marginTop: "5%" }}>
                <Text
                  style={{
                    color: "white",
                    // fontWeight: "bold",
                    fontSize: isMobile ? "3vw" : ".75vw"
                  }}
                >
                  For organization accounts or {client_label.toLowerCase()}{" "}
                  limits over 100 please contact us at{" "}
                </Text>
                <Col style={{ position: "relative", bottom: "1vw" }}>
                  <Col>
                    <Text
                      style={{
                        color: "white",
                        top: "1vw",
                        position: "relative",

                        fontSize: isMobile ? "3vw" : ".75vw",
                        color: Theme.PRIMARY
                      }}
                    >
                      (833) 789-6969
                    </Text>
                  </Col>
                </Col>
              </Col>

              <Col></Col>
            </Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginTop: isMobile ? "3vh" : "2vw",
                width: "35%",
                fontSize: isMobile ? "4vw" : ".75vw"
              }}
              onClick={() => setActiveTab("3")}
            >
              NEXT
            </Button>
          </Col>
        </TabPane>
        <TabPane tab="Confirm" key="3">
          <Col style={{ position: "relative" }}>
            <form
              onSubmit={handleSubmit}
              style={{
                // width: "50vw",
                // height: "20vw",
                position: "relative",
                top: ".5vw",
                marginTop: isMobile ? "5vw" : null,
                // right: "1vw",
                background: "transparent"
              }}
            >
              <h7
                style={{
                  fontSize: isMobile ? "5vw" : "1vw",
                  color: "white",
                  marginBottom: isMobile ? "10%" : "2.5%",

                  fontWeight: "bold"
                }}
              >
                Start Assessing Movement
              </h7>
              <Col>
                <Text
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? "5vw" : "1.25vw"
                  }}
                >
                  {price}{" "}
                  <Text
                    style={{
                      color: "white",
                      // fontWeight: "bold",
                      fontSize: isMobile ? "4vw" : ".65vw"
                    }}
                  >
                    per month
                  </Text>
                </Text>

                <Col>
                  <Text
                    style={{
                      color: "white",

                      position: "relative",

                      fontSize: isMobile ? "4vw" : ".75vw",
                      color: Theme.PRIMARY
                    }}
                  >
                    {selectedAmount} {client_label}s
                  </Text>
                </Col>
              </Col>
              <hr
                style={{
                  width: "100%",
                  borderWidth: 0.5,
                  opacity: 0.4,
                  marginTop: isMobile ? "4vh" : "1vw",
                  marginBottom: isMobile ? "4vh" : "1vw"
                }}
              />
              <Row
                justify="center"
                align="middle"
                style={{ position: "relative" }}
              >
                <Col
                  justify="center"
                  align="middle"
                  style={{
                    position: "relative",
                    width: isMobile ? "100%" : "20vw",
                    height: "15vw"
                  }}
                >
                  <Col style={{ marginTop: "0vw" }}>
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: isMobile ? "4vw" : ".75vw"
                      }}
                    >
                      Enter Payment Information
                    </Text>
                    <Col
                      align="start"
                      style={{
                        // border: null, backgroundColor:"black", opacity:.8, borderRadius: 10,
                        // borderRadius: "4px",
                        // padding: "8px",
                        marginTop: isMobile ? "4vw" : "1vw",
                        marginBottom: isMobile ? "10vw" : null,
                        width: isMobile ? "75vw" : null
                      }}
                    >
                      <div
                        style={{
                          padding: isMobile ? "2vw" : ".5vw",
                          borderRadius: 10,
                          border: "1px solid #d9d9d9"
                        }}
                      >
                        {/* <Text strong style={{fontSize: ".65vw"}}>Card Number</Text> */}

                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: Theme.WHITE,

                                "::placeholder": {
                                  color: "#aab7c4"
                                }
                              },
                              invalid: {
                                color: "#9e2146"
                              }
                            },
                            // Adjust the following CSS properties to fit your design
                            base: {
                              display: "flex",
                              flexDirection: "column",
                              padding: "10px",
                              backgroundColor: "#fff",
                              borderRadius: "4px",
                              border: "1px solid #d9d9d9"
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Col>

                  {loading ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginTop: "2vw",
                        width: "100%",
                        fontSize: ".75vw"
                      }}
                      disabled={!stripe}
                    >
                      <Spin size="small" />
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginTop: "2vw",
                        width: "100%",
                        fontSize: isMobile ? "4vw" : ".75vw"
                      }}
                      disabled={!stripe}
                    >
                      Subscribe
                    </Button>
                  )}
                  <Col style={{ top: "1vw", position: "relative" }}>
                    {error && (
                      <Col style={{ marginTop: isMobile ? "4vw" : ".5vw" }}>
                        <Text
                          style={{
                            color: "red",
                            fontSize: isMobile ? "4vw" : ".6vw"
                          }}
                        >
                          An Error Has Occured - Please Verify All Fields
                        </Text>
                      </Col>
                    )}
                    {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
                  </Col>
                </Col>
              </Row>
            </form>
          </Col>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            footer={null}
            onCancel={() => setModalShow(false)}
            open={modalShow}
            style={{ borderRadius: "10%" }}
          >
            <div centered style={{ alignSelf: "center" }}>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  fontSize: 25
                }}
              >
                Welcome to Demotu Premium!
              </p>

              <p style={{ textAlign: "center" }}>
                Your {user?.profile.client_label.toLowerCase()} amount has been
                set and you can <br /> now access premium features
              </p>
              <div>
                <button
                  style={{
                    marginTop: "25px",
                    marginBottom: "2vw",
                    backgroundColor: Theme.PRIMARY,
                    borderWidth: 0,
                    width: "50%",
                    alignSelf: "center",
                    color: Theme.WHITE,
                    borderRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    left: "24.5%",
                    position: "relative",
                    cursor: "pointer"
                  }}
                  onClick={goHome}
                  type="button"
                  className="sign-in-button"
                >
                  Let's Go!
                </button>
              </div>
            </div>
          </Modal>
        </TabPane>
      </Tabs>
    </Col>
  );
};

export default SubscriptionForm;
