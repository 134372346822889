import React, { useEffect, useState } from "react";

import {
  Card,
  Col,
  Input,
  Modal,
  Row,
  Typography,
  Pagination,
  Select,
  Radio,
  Tabs,
  Dropdown,
  Menu
} from "antd";
import {
  DotChartOutlined,
  UserOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import lightmode from "../assets/images/lightmode.png";
import darkmode from "../assets/images/darkmode.png";
import {
  getProfiles,
  getTrendReport,
  resetClient,
  updatePhoto
} from "../redux/actions/clients";
import genericPofile from "../assets/images/profilepic.jpg";
import guestPic from "../assets/images/guestpic.png";
import { useDispatch, useSelector } from "react-redux";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

import {
  checkAuthenticated,
  load_user,
  updateProfile
} from "../redux/actions/auth";
import quotes from "../constants/quotes";
import {
  getClients,
  getSingleClient,
  searchClients
} from "../redux/actions/clients";
import {
  getTrainerGroups,
  getSingleTrainerGroup,
  searchTrainerGroups,
  deleteTrainerGroup
} from "../redux/actions/trainer_groups";
import { getTrainers } from "../redux/actions/trainers";
import { checkStatus } from "../redux/actions/payment";
import { getGroups, resetGroup } from "../redux/actions/groups";
import { StyleSheet, css } from "aphrodite";
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import {
  IoAddCircleOutline,
  IoLogoApple,
  IoLogoAndroid
} from "react-icons/io5";
import CreateClientForm from "../components/results/CreateClientForm";
import CreateTrainerGroupForm from "../components/results/CreateTrainerGroupForm";
import DebounceSelect from "../components/loaders/DebounceSelect";
import { REACT_APP_API_URL } from "../env";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import CustomSelect from "../components/common/DropDown";
import BootstrapSearch from "../components/common/Search";
import {
  setQuery,
  setGroupFilter,
  setTrainerGroupFilter,
  setTrainerFilter,
  setPageNumber
} from "../redux/actions/session";
import runningman from "../assets/images/logowhite.png";
import runningman2 from "../assets/images/logo.png";

function Home() {
  const { Title, Text } = Typography;
  var status = "Howdy Howdy";
  var date = new Date();
  var hour = date.getHours();
  var trainerPhotoPath = genericPofile;
  var start = new Date(date.getFullYear(), 0, 0);
  var diff = date - start;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  const { user, isAuthenticated, Theme } = useSelector((state) => state.auth);
  const { trainers } = useSelector((state) => state.trainers);
  const { subscriptionStatus, compedMembership } = useSelector(
    (state) => state.payment
  );
  const { query, groupFilter, trainerGroupFilter, trainerFilter, pageNo } =
    useSelector((state) => state.session);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 850);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const history = useHistory();
  // if (!isAuthenticated || !user) {
  //   history.push("/sign-in");
  // }
  useEffect(() => {
    if (!isAuthenticated || !user) {
      history.push("/sign-in");
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && user && subscriptionStatus === false) {
      history.push("/subscribe");
    }
  }, [user, isAuthenticated, subscriptionStatus]);

  const dispatch = useDispatch();
  const fetchUser = () => dispatch(load_user());
  const [clientModalVisible, setClientModalVisible] = useState(false);
  const [groupModalVisible, setGroupModalVisible] = useState(false);

  const [darkModeModal, setDarkModeModal] = useState(
    user?.profile?.dark_mode === null
  );
  const [darkModeSelected, setdarkModeSelected] = useState(false);
  const [activeTab, setActiveTab] = useState("clients");

  const handleSearch = (text) => {
    dispatch(
      searchClients(text, groupFilter, trainerFilter, trainerGroupFilter)
    );
    dispatch(setQuery(text));
  };

  const handleGroupSearch = (text) => {
    dispatch(searchTrainerGroups(text, groupFilter));
    dispatch(setQuery(text));
  };
  const handleModeChange = (e) => {
    setdarkModeSelected(e.target.value);
  };

  const handleCloseModal = () => {
    dispatch(updateProfile(user?.profile?.id, { dark_mode: darkModeSelected }));
    dispatch(load_user())
    setDarkModeModal(false);
  };

  const ST_FRANCIS = 5;

  useEffect(() => {
    resetGroup();
    fetchUser();
    AOS.init();
    var date = new Date();
    hour = date.getHours();
    tokenCheck();

    dispatch(
      searchClients(
        query,
        groupFilter,
        trainerFilter,
        trainerGroupFilter,
        pageNo
      )
    );

    dispatch(resetClient());

    if (user?.profile?.account_type === 2) {
      dispatch(getTrainers());
      dispatch(getGroups());
    } else {
      dispatch(getTrainerGroups());
    }
    if (
      user?.profile?.account_type === 2 &&
      user?.profile?.organization === ST_FRANCIS
    ) {
      dispatch(getTrainerGroups());
    }
    dispatch(checkStatus);
    dispatch(getProfiles);
    dispatch(
      getTrendReport({
        selectedPeriod: "monthly",
        selectedYear: "pastyear",
        selectedGroup: [],
        selectedTrainer: []
      })
    );
  }, []);

  const { clients, total_clients, curr_clients } = useSelector(
    (state) => state.clients
  );
  const { trainer_groups } = useSelector((state) => state.trainer_groups);
  const { groups } = useSelector((state) => state.groups);

  const tokenCheck = () => dispatch(checkAuthenticated());

  const handleClick = (id, trainer) => {
    dispatch(getSingleClient(id));
    return history.push(`/client/${id}`);
  };

  const handleGroupClick = (id) => {
    dispatch(getSingleTrainerGroup(id));
    return history.push(`/trainergroup/${id}`);
  };

  if (hour < 12) {
    status = "Good Morning";
  } else if (hour >= 12 && hour < 17) {
    status = "Good Afternoon";
  } else {
    status = "Good Evening";
  }
  const [trainerPhoto, setTrainerPhoto] = useState(trainerPhotoPath);

  useEffect(() => {
    if (user?.profile?.photo != null) {
      setTrainerPhoto(user?.profile?.photo);
    }
  }, [user]);

  const count = [
    {
      today: `${user?.profile.client_label}s`,
      title: user && total_clients > 0 ? total_clients - 1 : total_clients,
      icon: <UserOutlined style={styles.icon} />,
      bnb: "bnb2"
    },
    {
      today: "Assessments",
      title: user && user?.stats?.assessments,
      icon: <DotChartOutlined style={styles.icon} />,
      bnb: "bnb2"
    }
  ];

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async () => {
        setTrainerPhoto(reader.result);
        dispatch(
          updatePhoto(
            user?.profile?.id,
            selectedFile,
            user?.profile?.account_type
          )
        );
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const paginateChange = (page) => {
    dispatch(setPageNumber(page));
    dispatch(
      searchClients(query, groupFilter, trainerFilter, trainerGroupFilter, page)
    );
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const genGroupItems = (userId) => [
    {
      key: userId,
      label: (
        <a
          onClick={(e) => {
            e.stopPropagation(); // This will stop the event from bubbling up to parent elements
            dispatch(deleteTrainerGroup(userId));
          }}
        >
          Remove
        </a>
      ),
      danger: true
    }
  ];

  const onClick = ({ key }) => {
    dispatch(deleteTrainerGroup(key));
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const itemRender = (current, type, originalElement) => {
    if (type === "page") {
      const color = Theme.PRIMARY;
      return <span style={{ color }}>{current}</span>;
    }
    return originalElement;
  };

  const fetchTrainersList = async (searchTerm) => {
    try {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: `JWT ${token}`
        }
      };

      const response = await axios.get(
        `${REACT_APP_API_URL}/api/organization/trainers?search=${searchTerm}`,
        config
      );

      return response.data.results.map((trainer) => ({
        label: `${trainer.user_account.first_name} ${trainer.user_account.last_name}`,
        value: trainer.user_account.id.toString()
      }));
    } catch (error) {
      return [];
    }
  };

  const trainerFirstPage = trainers.map((trainer) => ({
    label: `${trainer.user_account.first_name} ${trainer.user_account.last_name}`,
    value: trainer.user_account.id.toString()
  }));

  return isMobile ? (
    <Col
      align="middle"
      justify="center"
      style={{ position: "relative", bottom: "15vw", right: "7.5vw" }}
    >
      <img
        style={{ height: "8.5vw" }}
        className={`fade-in-slide-up-element ${
          true ? "fade-in-slide-up-animation" : ""
        }`}
        src={Theme.BACKGROUND === "#000" ? runningman : runningman2}
        alt=""
      />
      <Col style={{ width: "65%" }}>
        <p
          style={{
            fontSize: "4.5vw",
            width: "95%",
            color: Theme.TEXT,
            fontWeight: "lighter"
          }}
          className="font-semibold text-muted text-center"
        >
          Make your way over to our mobile app for the most optimized experience
        </p>
        <p
          style={{
            fontSize: "4.5vw",
            width: "95%",
            color: Theme.TEXT,
            fontWeight: "lighter"
          }}
          className="font-semibold text-muted text-center"
        >
          Our web app works best on your desktop computer with a full screen window
        </p>
        <a
          href="https://demotu.page.link/move"
          className="button fullfield"
          style={{
            color: "white",
            border: "0px solid #C04848",
            borderRadius: "5px",
            padding: "20px 40px",
            textDecoration: "none",
            display: "inline-block",
            transition: "background-color 0.3s, color 0.3s",
            cursor: "pointer", // Add pointer cursor on hover
            backgroundImage: "linear-gradient(60deg, #480048 5%, #C04848 120%)",
            marginTop: "3vw"
          }}
        >
          <Text style={{ fontSize: "3vw", color: "white", fontWeight: "bold" }}>
            Go to App
          </Text>
        </a>
      </Col>
    </Col>
  ) : (
    <Col
      justify="center"
      style={{
        position: "relative",
        right: "2.5vw"
      }}
    >
      <Row
        justify="center"
        align={"middle"}
        style={{
          margin: "0 2vw", // Add horizontal spacing of 2vw on both sides
          display: "flex", // Use flexbox
          justifyContent: "center"
        }}
      >
        <Card
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="1000"
          style={{
            paddingTop: ".05vw",
            paddingBottom: ".05vw",
            width: "25vw",
            backgroundColor: Theme.CARD,
            borderWidth: 0
          }}
        >
          <Row
            align="middle"
            justify="center"
            style={{
              position: "relative",
              fontSize: "1vw",
              paddingLeft: "5vw",
              paddingRight: "5vw"
            }}
          >
            <div
              style={{
                bottom: ".5vw",
                right: "6vw",
                position: "relative",
                borderRadius: 100,
                cursor: "pointer"
              }}
            >
              <img
                src={trainerPhoto}
                alt=""
                style={{
                  width: "4.25vw",
                  height: "4vw",
                  top: ".75vw",
                  position: "relative",
                  borderRadius: 100,
                  cursor: "pointer",
                  objectFit: "cover"
                }}
                object-fit="cover"
                onClick={handleImageClick}
                title="Upload"
              />
            </div>

            <input
              id="imageInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <div
              style={{
                position: "absolute",
                padding: "1vw",
                width: "15vw",
                left: "35%"
                // top: ".15vw",
              }}
            >
              <div Col>
                <Text
                  style={{
                    fontSize: "1vw",

                    fontWeight: "bold",
                    color: Theme.TEXT
                  }}
                >
                  {status},{" "}
                </Text>
                {user != null ? (
                  <Text
                    style={{
                      fontSize: "1vw",

                      fontWeight: "bold",
                      color: Theme.TEXT
                    }}
                  >
                    {user && user?.profile?.first_name}!
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </div>
            </div>
          </Row>
        </Card>
      </Row>

      <Row
        data-aos="fade-right"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="1500"
        style={{
          position: "relative",
          // width: "100vw",
          // top: "1.5vw",

          justifyContent: "center", // Center the content horizontally
          alignItems: "center" // Center the content vertically
        }}
      >
        {count.map((c, index) => (
          <Col
            key={index}
            style={{
              width: "17vw",
              backgroundColor: Theme.CARD,
              borderRadius: 10,
              boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)",
              // Adjust the width of each Col using vw units
              margin: "1vw .5vw" // Add horizontal spacing of 2vw on both sides
            }}
          >
            <Row align="middle">
              <Col xs={18} style={{ left: "2vw", position: "relative" }}>
                <span
                  style={{
                    fontSize: ".75vw",
                    position: "relative",
                    top: ".5vw",
                    color: Theme.TEXT2
                  }}
                >
                  {c.today}
                </span>{" "}
                {/* Adjust font size */}
                {c.title ? (
                  <Title
                    style={{
                      fontSize: "1vw",
                      left: "3%",
                      bottom: ".25vw",
                      position: "relative",
                      color: Theme.TEXT
                    }}
                  >
                    {c.title}
                  </Title>
                ) : (
                  <Title
                    style={{
                      fontSize: "1.25vw",
                      left: "3%",
                      bottom: ".25vw",
                      position: "relative",
                      color: Theme.TEXT
                    }}
                  >
                    0
                  </Title>
                )}
              </Col>
              <Col xs={6} align={"center"} justify={"center"}>
                <Col
                  align={"center"}
                  justify={"center"}
                  style={{
                    right: "2vw",
                    position: "relative",
                    width: "2.5vw",
                    height: "2.5vw",
                    textAlign: "center",
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    borderRadius: ".75rem"
                  }}
                  className="icon-box"
                >
                  {c.icon}
                </Col>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      <Row
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="750"
        style={{
          position: "relative",
          marginBottom: 500
        }}
        gutter={[24, 0]}
      >
        <Col
          style={{
            paddingLeft: "4vw",
            paddingRight: "4vw"
          }}
          xs={28}
          sm={24}
          md={18}
          lg={18}
          xl={16}
        >
          <Tabs
            style={{ color: Theme.TEXT, outline: "none" }}
            activeKey={activeTab}
            onChange={setActiveTab}
          >
            <Tabs.TabPane tab="Clients" key="clients">
              <Card
                align={"middle"}
                justify={"middle"}
                style={{
                  width: "60vw",
                  backgroundColor: Theme.CARD,
                  borderWidth: 0
                }}
                className="criclebox cardbody"
              >
                <Row
                  align={"middle"}
                  justify={"middle"}
                  className="project-ant"
                  style={{ marginBottom: "1.5vw" }}
                >
                  <BootstrapSearch
                    placeholder={`Search ${user?.profile.client_label}s`}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                    width="10vw"
                    value={query}
                  />
                  {user?.profile?.account_type === 1 && (
                    <CustomSelect
                      width="10vw"
                      showSearch
                      placeholder={
                        <span
                          style={{
                            color:
                              Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal"
                          }}
                        >
                          Filter Groups
                        </span>
                      }
                      mode="multiple"
                      tokenSeparators={[","]}
                      optionFilterProp="children"
                      onChange={(selectedValues) => {
                        dispatch(setTrainerGroupFilter(selectedValues));
                        dispatch(
                          searchClients(
                            query,
                            groupFilter,
                            trainerFilter,
                            selectedValues
                          )
                        );
                      }}
                      options={trainer_groups.map((group) => ({
                        value: group.id,
                        label: group.name
                      }))}
                      value={trainerGroupFilter}
                    />
                  )}
                  {user?.profile?.account_type === 2 && (
                    <CustomSelect
                      style={{
                        width: "10vw",
                        height: "auto",
                        borderRadius: "7.5px",
                        color: Theme.WHITE,
                        background: Theme.WHITE,
                        borderWidth: 0.5,
                        borderColor: Theme.INPUT
                      }}
                      placeholder={
                        <span
                          style={{
                            color:
                              Theme.BACKGROUND === "#000"
                                ? Theme.TEXT2
                                : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal"
                          }}
                        >
                          Filter by Trainer
                        </span>
                      }
                      mode="multiple"
                      options={trainerFirstPage.concat([
                        { value: -1, label: "Unassigned" }
                      ])}
                      onChange={(selectedValues) => {
                        dispatch(setTrainerFilter(selectedValues));
                        dispatch(
                          searchClients(query, groupFilter, selectedValues)
                        );
                      }}
                      width={"10vw"}
                      value={trainerFilter}
                    />
                  )}

                  {groups.length > 0 && (
                    <CustomSelect
                      style={{ width: "10vw" }}
                      placeholder={
                        <span
                          style={{
                            color:
                              Theme.BACKGROUND === "#000"
                                ? Theme.TEXT2
                                : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal"
                          }}
                        >
                          Filter by Team
                        </span>
                      }
                      mode="multiple"
                      options={groups.map((group) => ({
                        value: group.id,
                        label: group.name
                      }))}
                      onChange={(selectedValues) => {
                        dispatch(setGroupFilter(selectedValues));
                        dispatch(
                          searchClients(query, selectedValues, trainerFilter)
                        );
                      }}
                      value={groupFilter}
                      width={"10vw"}
                    />
                  )}

                  <Button
                    onClick={() => setClientModalVisible(true)}
                    style={{
                      color: "white",
                      borderWidth: 0,
                      borderRadius: 12,
                      height: "2vw",
                      width: "8vw",
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      cursor: "pointer",

                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                    }}
                  >
                    <IoAddCircleOutline
                      style={{
                        top: "5%",
                        marginRight: "4%",
                        position: "relative"
                      }}
                    />
                    New {user?.profile.client_label}
                  </Button>
                </Row>
                {clients.length > 0 ? (
                  <Col
                    style={{ paddingLeft: "5vw", paddingRight: "2.5vw" }}
                    align="middle"
                    justify="center"
                    className="ant-list-box table-responsive"
                  >
                    <table
                      className="width-100"
                      style={{ position: "relative", left: "1vw" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: ".85vw",
                              color: Theme.TEXT2,
                              position: "relative",
                              right: "5%"
                              // left:"3%"
                            }}
                          >
                            {user?.profile.client_label.toUpperCase()}
                          </th>{" "}
                          <th
                            style={{
                              fontSize: ".85vw",
                              right: "3%",
                              position: "relative",
                              color: Theme.TEXT2
                            }}
                          >
                            TOTAL ASSESSMENTS
                          </th>{" "}
                          <th
                            style={{
                              fontSize: ".85vw",
                              position: "relative",
                              // right: "1vw",
                              color: Theme.TEXT2
                            }}
                          >
                            LAST ASSESSMENT
                          </th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map((d, index) => {
                          const last_assessment = new Date(d.last_assessment);
                          var clientPhotoPath = genericPofile;
                          if (d.isGuest) {
                            clientPhotoPath = guestPic;
                          }
                          if (d.profile_picture != null) {
                            clientPhotoPath = d.profile_picture;
                          }

                          return (
                            <tr
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(d.id, d.trainer)}
                            >
                              <td
                                style={{
                                  fontSize: ".75vw",
                                  position: "relative",
                                  right: "5%"
                                }}
                              >
                                <Row
                                  align={"middle"}
                                  style={{ fontSize: "1vw" }}
                                >
                                  <img
                                    src={clientPhotoPath}
                                    alt=""
                                    className="avatar-sm mr-10"
                                    style={{
                                      height: "3vw", // Adjust the image height using vw units
                                      width: "3vw",
                                      position: "relative",
                                      objectFit: "cover",
                                      borderRadius: 100
                                    }}
                                  />
                                  {"   "}
                                  <p
                                    style={{
                                      position: "relative",
                                      left: "1vw",
                                      fontSize: ".85vw",
                                      color: Theme.TEXT
                                    }}
                                  >
                                    {" "}
                                    {d.first_name} {d.last_name}
                                  </p>
                                </Row>
                              </td>

                              <td
                                style={{
                                  fontSize: ".85vw",
                                  position: "relative",
                                  // right: ".5vw",
                                  color: Theme.TEXT2,
                                  position: "relative",
                                  left: "2vw",
                                  alignSelf: "center"
                                }}
                              >
                                <span
                                  className="text-xs font-weight-bold"
                                  style={{ color: Theme.TEXT2 }}
                                >
                                  {d.total_assessments}{" "}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: ".85vw",
                                  position: "relative",
                                  // right: "1vw",
                                  color: Theme.TEXT2
                                }}
                              >
                                {d.last_assessment !== null ? (
                                  <Col
                                    style={{
                                      position: "relative",
                                      right: "2.5vw"
                                    }}
                                    align="middle"
                                  >
                                    {d.last_assessment_score && (
                                      <CircularProgressbar
                                        styles={{
                                          // Customize the root svg element

                                          root: { height: "3vw" },
                                          // Customize the path, i.e. the "completed progress"
                                          path: {
                                            // Path color
                                            stroke:
                                              d.last_assessment_score >= 80
                                                ? Theme.GRAD6
                                                : d.last_assessment_score >= 65
                                                ? Theme.YELLOW
                                                : Theme.RED,
                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: "butt",
                                            // Customize transition animation
                                            transition:
                                              "stroke-dashoffset 0.5s ease 0s",
                                            // Rotate the path
                                            transformOrigin: "center center"
                                          },
                                          // Customize the circle behind the path, i.e. the "total progress"
                                          trail: {
                                            // Trail color
                                            stroke: Theme.INPUT,
                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: "butt",
                                            // Rotate the trail
                                            transformOrigin: "center center"
                                          },
                                          // Customize the text
                                          text: {
                                            // Text color
                                            fill: Theme.TEXT,
                                            // Text size
                                            fontSize: "1.5vw"
                                          },
                                          // Customize background - only used when the `background` prop is true
                                          background: {
                                            fill: "#3e98c7"
                                          }
                                        }}
                                        value={d.last_assessment_score.toFixed(
                                          0
                                        )}
                                        text={d.last_assessment_score.toFixed(
                                          0
                                        )}
                                      />
                                    )}
                                    <Col>
                                      {last_assessment ? (
                                        <Text
                                          style={{
                                            fontSize: ".75vw",
                                            color: Theme.TEXT,
                                            position: "relative",
                                            top: ".25vw"
                                          }}
                                        >
                                          {last_assessment.toLocaleDateString()}
                                        </Text>
                                      ) : (
                                        <Text
                                          style={{
                                            fontSize: ".55vw",
                                            color: Theme.TEXT
                                          }}
                                        >
                                          &nbsp;
                                        </Text>
                                      )}{" "}
                                    </Col>
                                  </Col>
                                ) : (
                                  <Col
                                    style={{
                                      position: "relative",
                                      right: "2.5vw"
                                    }}
                                    align="middle"
                                  >
                                    <CircularProgressbar
                                      styles={{
                                        // Customize the root svg element

                                        root: { height: "3vw" },
                                        // Customize the path, i.e. the "completed progress"
                                        path: {
                                          // Path color
                                          stroke: "red",
                                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                          strokeLinecap: "butt",
                                          // Customize transition animation
                                          transition:
                                            "stroke-dashoffset 0.5s ease 0s",
                                          // Rotate the path
                                          transformOrigin: "center center"
                                        },
                                        // Customize the circle behind the path, i.e. the "total progress"
                                        trail: {
                                          // Trail color
                                          stroke: Theme.INPUT,
                                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                          strokeLinecap: "butt",
                                          // Rotate the trail
                                          transformOrigin: "center center"
                                        },
                                        // Customize the text
                                        text: {
                                          // Text color
                                          fill: Theme.TEXT,
                                          // Text size
                                          fontSize: "1vw"
                                        },
                                        // Customize background - only used when the `background` prop is true
                                        background: {
                                          fill: "#3e98c7"
                                        }
                                      }}
                                      value={0}
                                      text={"N/A"}
                                    />
                                  </Col>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                ) : (
                  <Col
                    style={{
                      textAlign: "center",
                      marginTop: "5vw",
                      marginBottom: "3vw"
                    }}
                  >
                    <UserAddOutlined
                      style={{ fontSize: "3vw", color: Theme.PRIMARY }}
                    />
                    <Text
                      style={{
                        display: "block",
                        color: Theme.TEXT,
                        marginTop: 10,
                        fontSize: "1vw"
                      }}
                    >
                      No {user?.profile.client_label}s
                    </Text>
                  </Col>
                )}
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    paddingTop: "1vw"
                  }}
                >
                  <Pagination
                    onChange={paginateChange}
                    total={curr_clients}
                    defaultCurrent={pageNo}
                    defaultPageSize={10}
                    itemRender={itemRender}
                    showSizeChanger={false}
                  />
                </Row>
              </Card>
            </Tabs.TabPane>
            {(user?.profile?.account_type === 1 ||
              user?.profile?.organization === ST_FRANCIS) && (
              <Tabs.TabPane tab="Groups" key="groups">
                <Card
                  style={{
                    width: "60vw",
                    backgroundColor: Theme.CARD,
                    borderWidth: 0
                  }}
                  className="criclebox cardbody"
                >
                  <Row
                    className="project-ant"
                    style={{ marginBottom: "1.5vw" }}
                  >
                    <BootstrapSearch
                      placeholder="Search Groups"
                      onSearch={handleGroupSearch}
                      onChange={(e) => handleGroupSearch(e.target.value)}
                      width="10vw"
                    />
                    <Button
                      onClick={() => setGroupModalVisible(true)}
                      style={{
                        color: "white",
                        borderWidth: 0,
                        borderRadius: 12,
                        height: "2vw",
                        width: "7vw",
                        fontWeight: "bold",
                        fontSize: ".75vw",
                        cursor: "pointer",

                        backgroundImage:
                          "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                      }}
                    >
                      <IoAddCircleOutline
                        style={{
                          top: "5%",
                          marginRight: "4%",
                          position: "relative"
                        }}
                      />
                      New Group
                    </Button>
                  </Row>
                  <div
                    style={{ paddingLeft: "4vw" }}
                    className="ant-list-box table-responsive"
                  >
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: ".85vw",
                              color: Theme.TEXT2,
                              position: "relative",
                              right: "2.5%"
                            }}
                          >
                            NAME
                          </th>{" "}
                          <th
                            style={{
                              fontSize: ".85vw",
                              left: "1%",
                              position: "relative",
                              color: Theme.TEXT2
                            }}
                          >
                            TOTAL {user?.profile.client_label.toUpperCase()}S
                          </th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        {trainer_groups.map((d, index) => {
                          return (
                            <tr
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleGroupClick(d.id)}
                            >
                              <td
                                style={{
                                  fontSize: ".75vw",
                                  position: "relative",
                                  right: "5%"
                                }}
                              >
                                <Row style={{ fontSize: "1vw" }}>
                                  <p
                                    style={{
                                      position: "relative",
                                      left: "1.5vw",
                                      fontSize: ".85vw",
                                      color: Theme.TEXT
                                    }}
                                  >
                                    {" "}
                                    {d.name}
                                  </p>
                                </Row>
                              </td>

                              <td
                                style={{
                                  fontSize: ".85vw",
                                  position: "relative",
                                  right: ".5vw",
                                  color: Theme.TEXT2,
                                  position: "relative",
                                  left: "6%"
                                }}
                              >
                                <span
                                  className="text-xs font-weight-bold"
                                  style={{ color: Theme.TEXT2 }}
                                >
                                  {d.clients.length}{" "}
                                </span>
                              </td>
                              <td style={{ position: "relative", left: "5%" }}>
                                <Dropdown
                                  trigger={["click"]}
                                  menu={{
                                    items: genGroupItems(d.id),
                                    onClick
                                  }}
                                >
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation(); // Stop the click event from bubbling up to the parent <tr>
                                    }}
                                    style={{
                                      display: "inline-block",
                                      padding: "5px",
                                      cursor: "pointer"
                                    }}
                                  >
                                    Edit
                                    {/* <DownOutlined /> */}
                                  </a>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      paddingTop: "1vw"
                    }}
                  ></Row>
                </Card>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
      <Modal
        open={clientModalVisible}
        onCancel={() => setClientModalVisible(false)}
        footer={null}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Row style={{ padding: "5%" }}>
          <Col span={24}>
            <CreateClientForm closeModal={setClientModalVisible} />
          </Col>
        </Row>
      </Modal>
      <Modal
        open={groupModalVisible}
        onCancel={() => setGroupModalVisible(false)}
        footer={null}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <CreateTrainerGroupForm closeModal={setGroupModalVisible} />
      </Modal>
      {user && (
        <Modal
          open={darkModeModal}
          onCancel={handleCloseModal}
          cancelButtonProps={{ style: { display: "none" } }} // Hide the cancel button
          closeIcon={<span></span>}
          footer={[
            <Col align={"center"} justify={"center"}>
              <Button
                key="ok"
                type="primary"
                style={{
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                  borderRadius: 10,
                  borderWidth: 0,
                  color: "white",
                  fontFamily: "Arial",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  paddingTop: "2.5%",
                  paddingBottom: "2.5%",
                  cursor: "pointer",
                  fontWeight: "bold",
                  alignSelf: "center"
                }}
                onClick={handleCloseModal}
              >
                Let's Go!
              </Button>
            </Col>
          ]}
          width={"30vw"}
        >
          <Row
            align={"center"}
            justify={"center"}
            style={{
              padding: "5%"
            }}
          >
            <Col span={24} align={"center"} justify={"center"}>
              <Text
                style={{
                  position: "relative",
                  left: "2.5%",
                  fontSize: "1vw",
                  fontWeight: "bold"
                }}
              >
                Welcome to Demotu! 👋
              </Text>
              <div style={{ marginTop: "2.5%" }}>
                <Text style={{ fontSize: ".75vw" }}>
                  Download the app to take assessments
                </Text>
              </div>
              <Row
                align={"center"}
                justify={"center"}
                style={{ marginTop: "5%" }}
              >
                <Button
                  onClick={() =>
                    (window.location.href =
                      "https://apps.apple.com/app/id1643253527?platform=iphone")
                  }
                  style={{
                    backgroundColor: "transparent",
                    // color: "white",
                    borderWidth: 1,
                    borderRadius: 12,
                    height: "2vw",
                    width: "8vw",
                    fontWeight: "bold",
                    fontSize: ".75vw",
                    cursor: "pointer",
                    fontSize: ".75vw",
                    marginRight: "5%"
                    // backgroundImage:
                    //   "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                  }}
                >
                  <IoLogoApple
                    style={{
                      top: "5%",
                      marginRight: "4%",
                      position: "relative"
                    }}
                  />
                  <Text style={{ fontSize: ".55vw" }}> iOS Download</Text>
                </Button>

                <Button
                  onClick={() =>
                    (window.location.href =
                      "https://play.google.com/store/apps/details?id=com.eledahl.demotu")
                  }
                  style={{
                    backgroundColor: "transparent",
                    // color: "white",
                    borderWidth: 1,
                    borderRadius: 12,
                    height: "2vw",
                    width: "8vw",
                    fontWeight: "bold",
                    fontSize: ".75vw",
                    cursor: "pointer",
                    fontSize: ".75vw"
                    // backgroundImage:
                    //   "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                  }}
                >
                  <IoLogoAndroid
                    style={{
                      top: "5%",
                      marginRight: "4%",
                      position: "relative"
                    }}
                  />
                  <Text style={{ fontSize: ".55vw" }}> Android Download</Text>
                </Button>
              </Row>
              <hr
                style={{
                  marginTop: "2vw",
                  marginBottom: "2vw",
                  border: "none",
                  borderBottom: "1px solid #ccc"
                }}
              />

              <Col style={{ marginBottom: "1.5vw" }}>
                <Text style={{ fontWeight: "bold", fontSize: "1vw" }}>
                  Choose Your Theme
                </Text>
                <div>
                  <Text style={{ fontSize: ".65vw" }}>
                    You can update your theme at any time in account settings
                  </Text>
                </div>
              </Col>
              <Row
                align={"center"}
                justify={"center"}
                style={{ textAlign: "center" }}
              >
                <Col
                  align={"center"}
                  justify={"center"}
                  span={12}
                  style={{ marginRight: ".0vw", textAlign: "center" }}
                >
                  <div>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: ".75vw"
                      }}
                    >
                      Light Theme
                    </Text>
                  </div>
                  <img
                    src={lightmode}
                    alt="Light Mode"
                    style={{
                      width: "11vw",
                      height: "7vw",
                      marginTop: "5%",
                      marginBottom: "5%"
                    }}
                  />

                  <div>
                    <Radio
                      value={false}
                      onChange={handleModeChange}
                      checked={darkModeSelected === false}
                    />
                  </div>
                </Col>
                <Col
                  align={"center"}
                  justify={"center"}
                  span={12}
                  style={{ marginLeft: "0vw" }}
                >
                  <div>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: ".75vw"
                      }}
                    >
                      Dark Theme
                    </Text>
                  </div>
                  <img
                    src={darkmode}
                    alt="Dark Mode"
                    style={{
                      width: "11vw",
                      height: "7vw",
                      marginTop: "5%",
                      marginBottom: "5%"
                    }}
                  />

                  <div>
                    <Radio
                      value={true}
                      onChange={handleModeChange}
                      checked={darkModeSelected === true}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      )}
    </Col>
  );
}

export default Home;

const styles = StyleSheet.create({});
