import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  LOGOUT,
  RESET_AUTH_STATUS,
  REFRESH_TOKEN,
  UPDATE_EMAIL,
  UPDATE_PROFILE,
  DELETE_ACCOUNT,
  RESET_PASS_SUCCESS,
} from "../actions/types";
import { getColors } from "../../constants/Theme";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  user: null,
  signInStatus: null,
  signUpStatus: null,
  updateEmailStatus: null,
  subscriptionStatus: null,
  freeTrial: null,
  compedMembership: null,
  statusChecked: null,
  freeTrial: null,
  isPromo: null,
  isFounder: null,
  Theme: getColors('light'),
  passSuccess: null
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };
    case LOGIN_SUCCESS:
    case GOOGLE_AUTH_SUCCESS:
    case FACEBOOK_AUTH_SUCCESS:
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        signUpStatus: payload.status
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            measurement: payload.measurement
          },
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email
        },
        Theme: getColors(payload.dark_mode ? "dark" : "light")
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
        Theme: getColors(payload.profile.dark_mode ? "dark" : "light")
      };
    case REFRESH_TOKEN:
      localStorage.setItem("access", payload.access);
      return {
        ...state,
        isAuthenticated: true
      };
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null
      };
    case GOOGLE_AUTH_FAIL:
    case FACEBOOK_AUTH_FAIL:
    case LOGOUT:
    case DELETE_ACCOUNT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null
      };
    case LOGIN_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        signInStatus: payload
      };
    case SIGNUP_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        signUpStatus: payload
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        updateEmailStatus: payload
      };
    case RESET_AUTH_STATUS:
      return {
        ...state,
        signUpStatus: null,
        signInStatus: null
      };
    case PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        ...state,
        passSuccess: true,
        resetStatus: payload
      };
    case PASSWORD_RESET_FAIL:
    case PASSWORD_RESET_CONFIRM_FAIL:
      return {
        ...state,
        passSuccess: false,
        resetStatus: payload
      };
    case RESET_PASS_SUCCESS:
      return {
        ...state,
        passSuccess: null
      };
    case PASSWORD_RESET_SUCCESS:
    case ACTIVATION_SUCCESS:
    case ACTIVATION_FAIL:
      return {
        ...state
      };

    default:
      return state;
  }
}
