export const getColors = (colorScheme) => {


  const lightColors = {
    DEFAULT:'#C04848',
    PRIMARY: '#C04848',
    SECONDARY: '#e58ac7',
    LABEL: '#FE2472',
    INFO: '#11CDEF',
    ERROR: '#F5365C',
    SUCCESS: '#2DCE89',
    POP: '#FFFFFF',
    GRAD1: "#C04848",
    GRAD2: "#9B2E48",
    GRAD3: "#761747",
    GRAD4: "#480048",
    GRAD5: "#C04848",
    GRAD6: "#0EFF7D",
    GRAD7: "#0E47FF",
    LINE: '#00D1FF',
    CIRCLE: '#FF66C4', 
    MUTED: '#D3D3D3',
    INPUT: '#DCDCDC',
    INPUT_SUCCESS: '#7BDEB2',
    INPUT_ERROR: '#FCB3A4',
    ACTIVE: '#5E72E4', 
    BUTTON_COLOR: '#6a6a6a',
    PLACEHOLDER: '#9FA5AA',
    SWITCH_ON: '#5E72E4',
    SWITCH_OFF: '#D4D9DD',
    TEST1: '#192024',
    TEST2: '#192024',
    TEST4: "#171919",
    TEST3: '#000',
    BORDER_COLOR: '#E7E7E7',
    BLOCK: '#E7E7E7',
    ICON: '#172B4D',
    HEADER: '#8C52FF',
    BORDER: '#CAD1D7',
    WHITE: '#FFFFFF',
    BLACK: '#2A2A2A',
    BODY: '#C04848',
    RECORD_BUTTON: '#EEEEEE',
    BACKGROUND: "#f1f1fb",
    CARD: "#fcfcfb" ,
    TEXT: "#000",
    TEXT2: "#38474e",
    CHART1: "#C04848",
    CHART2: "#480048",
    RED: "#FF0026",
    YELLOW: "#E4C109",
    BLUE: "#67AEE6",
    SIDEBAR: "#000"
  };

  const darkColors = {
    DEFAULT: "#C04848",
    PRIMARY: "#C04848",
    SECONDARY: "#e58ac7",
    LABEL: "#FE2472",
    INFO: "#11CDEF",
    ERROR: "#F5365C",
    SUCCESS: "#2DCE89",
    POP: "#FFFFFF",
    GRAD1: "#C04848",
    GRAD2: "#9B2E48",
    GRAD3: "#761747",
    GRAD4: "#480048",
    GRAD5: "#C04848",
    GRAD6: "#0EFF7D",
    GRAD7: "#0E47FF",
    LINE: "#00D1FF",
    CIRCLE: "#FF66C4",
    MUTED: "#D3D3D3",
    INPUT: "#000",
    INPUT_SUCCESS: "#7BDEB2",
    INPUT_ERROR: "#FCB3A4",
    ACTIVE: "#5E72E4",
    BUTTON_COLOR: "#6a6a6a",
    PLACEHOLDER: "#9FA5AA",
    SWITCH_ON: "#5E72E4",
    SWITCH_OFF: "#D4D9DD",
    TEST1: "#192024",
    TEST2: "#192024",
    TEST4: "#171919",
    TEST3: "#000",
    BORDER_COLOR: "#E7E7E7",
    BLOCK: "#E7E7E7",
    ICON: "#172B4D",
    HEADER: "#8C52FF",
    BORDER: "#CAD1D7",
    WHITE: "#FFFFFF",
    BLACK: "#2A2A2A",
    BODY: "#C04848",
    RECORD_BUTTON: "#EEEEEE",
    BACKGROUND: "#000",
    CARD: "#1A1E23",
    TEXT: "#fff",
    TEXT2: "#D3D3D3",
    CHART1: "#171919",
    CHART2: "#171919",
    RED: "#FF0026",
    YELLOW: "#FFDE00",
    BLUE: "#67AEE6",
    SIDEBAR: "#000"
  };

  return colorScheme === 'dark' ? darkColors : lightColors;
};

export default {
  COLORS: getColors(),
};
