import React, { useEffect, useState } from "react";
import { Spin, Card, Col, Button, Modal, Row, Typography, Select, InputNumber } from "antd";
import { StyleSheet, css } from "aphrodite";
import { getGroups } from "../redux/actions/groups";
import { useDispatch, useSelector } from "react-redux";
import { getTrendReport, getTrendReportJC } from "../redux/actions/clients";
import TrendReport from "../components/TrendReport";
import { getTrainers } from "../redux/actions/trainers";
import { IoInformationCircleOutline } from "react-icons/io5";
import CustomSelect from "../components/common/DropDown";
import TrendReportJC from "../components/TrendReportJC";

const { Text } = Typography;

function TrendReportDashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { Option } = Select;
  const { user, Theme } = useSelector((state) => state.auth);
  const { groups } = useSelector((state) => state.groups);
  const { trainers } = useSelector((state) => state.trainers);
  const [timeChangeLoad, setTimeChangeLoad] = useState(false);
  const { trend_report } = useSelector((state) => state.clients);
  const { trend_report_jc } = useSelector((state) => state.clients);
  const [lungeReport, setLungeReport] = useState({});
  const [rdlReport, setRDLReport] = useState({});
  const [cmjReport, setCmjReport] = useState({});
  const [squatReport, setSquatReport] = useState({});
  const [balanceReport, setBalanceReport] = useState({});
  const [aggregateReport, setAggregateReport] = useState({});
  const [pushReport, setPushReport] = useState({});

  const [hipJointReport, setHipJointReport] = useState({});
  const [kneeJointReport, setKneeJointReport] = useState({});
  const [shoulderJointReport, setShoulderJointReport] = useState({});
  const [ankleJointReport, setAnkleJointReport] = useState({});
  const [cervicalJointReport, setCervicalJointReport] = useState({});
  const [thoracicJointReport, setThoracicJointReport] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [localState, setLocalState] = useState({
    selectedPeriod: "monthly",
    selectedYear: "pastyear",
    selectedGroup: [],
    selectedTrainer: [],
    selectedMinAge: null,
    selectedMaxAge: null,
  });

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getTrainers());
  }, []);

  useEffect(() => {
    setTimeChangeLoad(true);
    dispatch(getTrendReport(localState));
    dispatch(getTrendReportJC(localState));
    if (trend_report != null && trend_report_jc != null) {
      setLoading(false);
      setTimeChangeLoad(false);
    }
  }, [localState]);

  useEffect(() => {
    setTimeChangeLoad(false);
    if (trend_report["averages"] != null) {
      setAggregateReport({
        aggregate: trend_report["averages"]["aggregate"]
      });
      setLungeReport({
        l_lunge: trend_report["averages"]["l_lunge"],
        r_lunge: trend_report["averages"]["r_lunge"],
        l_lat_lunge: trend_report["averages"]["l_lat_lunge"],
        r_lat_lunge: trend_report["averages"]["r_lat_lunge"]
      });
      setRDLReport({
        l_rdl: trend_report["averages"]["l_rdl"],
        r_rdl: trend_report["averages"]["r_rdl"],
        hinge: trend_report["averages"]["hinge"]
      });
      setSquatReport({
        squat: trend_report["averages"]["squat"],
        standard_squat: trend_report["averages"]["standard_squat"]
      });
      setCmjReport({
        cmj: trend_report["averages"]["cmj"]
      });
      setBalanceReport({
        l_balance: trend_report["averages"]["l_balance"],
        r_balance: trend_report["averages"]["r_balance"]
      });
      setPushReport({
        push: trend_report["averages"]["push"]
      });
    }
  }, [trend_report]);

  useEffect(() => {
    if (trend_report_jc["averages"] != null) {
      setHipJointReport({
        hip_abduction: trend_report_jc["averages"]["hip_abduction"],
        hip_flexion: trend_report_jc["averages"]["hip_flexion"],
        hip_extension: trend_report_jc["averages"]["hip_extension"],
        hip_external_rotation:
          trend_report_jc["averages"]["hip_external_rotation"],
        hip_internal_rotation:
          trend_report_jc["averages"]["hip_internal_rotation"]
      });

      setKneeJointReport({
        knee_extension: trend_report_jc["averages"]["knee_extension"],
        knee_flexion: trend_report_jc["averages"]["knee_flexion"]
      });

      setShoulderJointReport({
        shoulder_abduction: trend_report_jc["averages"]["shoulder_abduction"],
        shoulder_flexion: trend_report_jc["averages"]["shoulder_flexion"],
        shoulder_horizontal_abduction:
          trend_report_jc["averages"]["shoulder_horizontal_abduction"],
        shoulder_internal_rotation:
          trend_report_jc["averages"]["shoulder_internal_rotation"],
        shoulder_external_rotation:
          trend_report_jc["averages"]["shoulder_external_rotation"]
      });

      setAnkleJointReport({
        ankle_dorsiflexion: trend_report_jc["averages"]["ankle_dorsiflexion"],
        ankle_plantarflexion:
          trend_report_jc["averages"]["ankle_plantarflexion"]
      });

      setCervicalJointReport({
        cervical_flexion_extension:
          trend_report_jc["averages"]["cervical_movement"],
        cervical_lateral_flexion:
          trend_report_jc["averages"]["cervical_lateral_flexion"],
        cervical_rotation: trend_report_jc["averages"]["cervical_rotation"]
      });

      setThoracicJointReport({
        thoracic_trunk_rotation:
          trend_report_jc["averages"]["thoracic_rotation"]
      });
    }
  }, [trend_report_jc]);

  function handleSelectChange(param, value) {
    setTimeChangeLoad(true);
    setLocalState((prevState) => {
      let newState = {
        ...prevState,
        [param]: value
      };

      if (param === "selectedGroup" && prevState.selectedTrainer.length > 0) {
        newState.selectedTrainer = [];
      } else if (
        param === "selectedTrainer" &&
        prevState.selectedGroup.length > 0
      ) {
        newState.selectedGroup = [];
      }

      return newState;
    });
  }
  const lightModeBackground = Theme.CARD; // Default light mode background color
  const darkModeBackground = Theme.CARD || "#121212"; // Dark mode background color, defaulting to Theme.CARD

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Row
      align={"middle"}
      justify={"center"}
      style={{
        minHeight: "100vh",
        position: "relative",
        right: "5vw",
        color: Theme.TEXT
      }}
      className={`fade-in-slide-up-element ${
        true ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <Col align="middle" justify="center">
        <h1 style={{ position: "relative", left: ".5vw" }}>
          Trend Reports{" "}
          <IoInformationCircleOutline
            onClick={() => setModalVisible(true)}
            color={Theme.PRIMARY}
            size={".8vw"}
            style={{
              alignSelf: "center",
              // left: "2%",
              // top: ".2vw",
              zIndex: 80,
              cursor: "pointer",
              position: "relative"
            }}
          />
        </h1>
        <Modal
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          bodyStyle={{ height: "17vw", padding: "1vw" }}
          width={"30vw"}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Row justify="center">
            <Col span={24}>
              <h1
                style={{
                  position: "relative",
                  bottom: "1vw",
                  color: Theme.TEXT
                }}
              >
                Trend Reports
              </h1>
              <Text style={{ color: Theme.TEXT2, fontSize: ".95vw" }}>
                {" "}
                Average scores across all of your{" "}
                {user?.profile.client_label.toLowerCase()}s so you can track the
                effectiveness of your training on a macro level
              </Text>
            </Col>
          </Row>
        </Modal>
        <Row>
          <CustomSelect
            value={localState.selectedPeriod}
            style={{
              width: "7vw",
              position: "relative",
              marginBottom: "2.5%"
            }}
            onChange={(value) => handleSelectChange("selectedPeriod", value)}
            options={[
              { value: "monthly", label: "Monthly" },
              { value: "quarterly", label: "Quarterly" },
              { value: "sixweeks", label: "6 Weeks" }
            ]}
            width={"7vw"}
            lightModeBackground={lightModeBackground}
            darkModeBackground={darkModeBackground}
          />
          {groups.length > 0 && (
            <Col style={{ marginLeft: "1vw" }}>
              <CustomSelect
                width="7vw"
                showSearch
                value={localState.selectedGroup}
                placeholder={
                  <span
                    style={{
                      color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                      fontSize: ".65vw",
                      top: ".05vw",
                      position: "relative",
                      fontWeight: "normal"
                    }}
                  >
                    All Teams
                  </span>
                }
                mode="multiple"
                tokenSeparators={[","]}
                optionFilterProp="children"
                onChange={(selectedValues) => {
                  handleSelectChange("selectedGroup", selectedValues);
                }}
                filterOption={filterOption}
                options={groups.map((group) => ({
                  value: group.id,
                  label: group.name
                }))}
                disabled={groups.length === 0}
                lightModeBackground={lightModeBackground}
                darkModeBackground={darkModeBackground}
              />
            </Col>
          )}
          {groups.length > 0 && (
            <Col style={{ marginLeft: "1vw" }}>
              <CustomSelect
                width="7vw"
                defaultValue={localState.selectedTrainer}
                placeholder={
                  <span
                    style={{
                      color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                      fontSize: ".65vw",
                      fontWeight: "normal"
                    }}
                  >
                    All Trainers
                  </span>
                }
                mode="multiple"
                tokenSeparators={[","]}
                optionFilterProp="children"
                style={{
                  width: "7vw",
                  position: "relative",
                  marginLeft: "5vw",
                  color: "black"
                }}
                filterOption={filterOption}
                onChange={(selectedValues) => {
                  handleSelectChange("selectedTrainer", selectedValues);
                }}
                options={trainers.map((trainer) => ({
                  value: trainer.user_account.id,
                  label: `${trainer.user_account.first_name} ${trainer.user_account.last_name}`
                }))}
                disabled={trainers.length === 0}
                lightModeBackground={lightModeBackground}
                darkModeBackground={darkModeBackground}
              />
            </Col>
          )}
          <Col style={{ marginLeft: "1vw" }}>
            <CustomSelect
              showSearch
              allowClear
              placeholder={
                <span
                  style={{
                    color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                    fontSize: ".65vw",
                    fontWeight: "normal"
                  }}
                >
                  Min Age
                </span>
              }
              optionFilterProp="children"
              style={{
                width: "7vw",
                position: "relative",
                marginLeft: "5vw",
                color: "black",
                fontSize: ".65vw",
                fontWeight: "normal"
              }}
              onChange={(value) => {
                handleSelectChange("selectedMinAge", value);
              }}
              options={Array.from({ length: 100 }, (_, i) => {
                const age = i + 1;
                return {
                  value: age,
                  label: `${age}`
                };
              })}
              lightModeBackground={lightModeBackground}
              darkModeBackground={darkModeBackground}
              filterOption={filterOption}
            ></CustomSelect>
          </Col>

          <Col style={{ marginLeft: "1vw" }}>
            <CustomSelect
              showSearch
              allowClear
              placeholder={
                <span
                  style={{
                    color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                    fontSize: ".65vw",
                    fontWeight: "normal"
                  }}
                >
                  Max Age
                </span>
              }
              optionFilterProp="children"
              style={{
                width: "7vw",
                position: "relative",
                marginLeft: "5vw",
                color: "black",
                fontSize: ".65vw",
                fontWeight: "normal"
              }}
              onChange={(value) => {
                handleSelectChange("selectedMaxAge", value);
              }}
              options={Array.from({ length: 100 }, (_, i) => {
                const age = i + 1;
                return {
                  value: age,
                  label: `${age}`
                };
              })}
              lightModeBackground={lightModeBackground}
              darkModeBackground={darkModeBackground}
              filterOption={filterOption}
            ></CustomSelect>
          </Col>

          <Col style={{ marginLeft: "1vw" }}>
            <CustomSelect
              defaultValue={localState.selectedYear}
              style={{
                width: "7vw",
                marginLeft: ".5vw",
                position: "relative",
                marginBottom: "2.5%"
              }}
              onChange={(value) => handleSelectChange("selectedYear", value)}
              options={[
                { value: "pastyear", label: "Last 12 Months" },
                { value: "2024", label: "2024" },
                { value: "2023", label: "2023" }
              ]}
              lightModeBackground={lightModeBackground}
              darkModeBackground={darkModeBackground}
            />
          </Col>
        </Row>
      </Col>
      <div style={{ position: "relative", top: "1vw", marginBottom: "25vw" }}>
        <Row>
          <Col>
            {loading ? (
              <Spin size="large" style={{ marginBottom: "1vw" }} />
            ) : (
              <>
                <Row
                  justify="start"
                  style={{
                    marginBottom: "2vw",
                    position: "relative",
                    left: "1%"
                  }}
                >
                  <Col>
                    <Text
                      style={{
                        fontSize: ".75vw",
                        fontWeight: "bold",
                        color: Theme.TEXT,
                        textAlign: "left"
                      }}
                    >
                      Functional Movements
                    </Text>
                  </Col>
                </Row>
                <div className={css(styles.widgetGrid)}>
                  <TrendReport
                    trend_reports={aggregateReport}
                    movements={["aggregate"]}
                    title={"Aggregate"}
                    chartColor={Theme.BLUE}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                  <TrendReport
                    trend_reports={cmjReport}
                    movements={["cmj"]}
                    title="Countermovement Jump"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                  <TrendReport
                    trend_reports={squatReport}
                    movements={["squat"]}
                    title="Squat"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                  <TrendReport
                    trend_reports={lungeReport}
                    movements={["l_lunge"]}
                    title="Lunge"
                    chartColor={Theme.BLUE}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                  <TrendReport
                    trend_reports={rdlReport}
                    movements={["hinge"]}
                    title="Hinge"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                  <TrendReport
                    trend_reports={balanceReport}
                    movements={["l_balance"]}
                    title="Balance"
                    chartColor={Theme.BLUE}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                  <TrendReport
                    trend_reports={pushReport}
                    movements={["push"]}
                    title="Push"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                </div>
                <Row
                  justify="start"
                  style={{
                    marginBottom: "2vw",
                    position: "relative",
                    left: "1%",
                    marginTop: "5vw"
                  }}
                >
                  <Col>
                    <Text
                      style={{
                        fontSize: ".75vw",
                        fontWeight: "bold",
                        color: Theme.TEXT,
                        textAlign: "left"
                      }}
                    >
                      Joint Comparisons
                    </Text>
                  </Col>
                </Row>

                <div className={css(styles.widgetGrid)}>
                  <TrendReportJC
                    trend_reports={hipJointReport}
                    movements={["hip_abduction"]}
                    title="Hip"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />

                  <TrendReportJC
                    trend_reports={kneeJointReport}
                    movements={["knee_extension"]}
                    title="Knee"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />

                  <TrendReportJC
                    trend_reports={shoulderJointReport}
                    movements={["shoulder_abduction"]}
                    title="Shoulder"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />

                  {/* <TrendReportJC
                    trend_reports={ankleJointReport}
                    movements={["ankle_dorsiflexion"]}
                    title="Ankle"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  /> */}

                  <TrendReportJC
                    trend_reports={cervicalJointReport}
                    movements={["cervical_flexion_extension"]}
                    title="Cervical"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />

                  <TrendReportJC
                    trend_reports={thoracicJointReport}
                    movements={["thoracic_trunk_rotation"]}
                    title="Trunk"
                    chartColor={Theme.GRAD6}
                    period={localState.selectedPeriod}
                    timeLoad={timeChangeLoad}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </Row>
  );
}

export default TrendReportDashboard;

const styles = StyleSheet.create({
  widgetGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "10px"
  }
});
