import React, { useEffect, useRef, useState } from "react";
import { aoc } from "../../constants/aocKeys";
import { Col, Row, Typography } from "antd";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import frontPost from "../../assets/images/Body.png";
import { useSelector } from "react-redux";
const { Text } = Typography;

const Body = ({ areasOfConcerns, movements }) => {
  const [array, setArray] = useState([]);
  const [filteredKeys, setFilteredKeys] = useState([]);
  const { Theme } = useSelector((state) => state.auth);
  const filterConcerns = () => {
    const result = aoc.filter((concern) => {
      return array.find((selected) => selected === concern.imbalance);
    });
    setFilteredKeys(result);
  };
  useEffect(() => {
    let arr = [];
    for (const area in areasOfConcerns) {
      arr.push(areasOfConcerns[area]);
    }
    setArray(arr);
    setFilteredKeys();
  }, [areasOfConcerns]);

  useEffect(() => {
    filterConcerns();
  }, [array]);

  if (array.length > Object.keys(areasOfConcerns).length) {
    let arr = [];
    for (const area in areasOfConcerns) {
      arr.push(areasOfConcerns[area]);
    }
    setArray(arr);
  }
  const imageHeightVw = 10; // Set th
  const imageAspectRatio = 1.3 / 1; // Set the aspect ratio
  // Calculate the image height in pixels based on the viewport width (10vw)
  const imageHeightPx = (10 / 100) * window.innerWidth;
  const mq = window.matchMedia("(min-width: 1100px)");

  // Calculate the image width in pixels based on the aspect ratio and image height
  const imageWidthPx = imageAspectRatio * imageHeightPx;
  return (
    <div
      className="aoc"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // minHeight: "13vw",
        // height: 'auto'
      }}
    >
      <h1
        style={{
          fontSize: "1vw",
          color: Theme.TEXT,
          // fontFamily: "ArialBold",
          position: "relative",
          // bottom: 10,
          marginBottom: "1.25vw",
        }}
      >
        Areas of Concern
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          top: 15,
          position: "relative",
          width: "75%", // Adjust the width as needed
        }}
      >
        <div
          style={{
            position: "relative",

            width: "100%",
            height: `${imageHeightVw}vw`,
          }}
        >
          <svg
            viewBox={`0 0 ${imageWidthPx} ${imageHeightPx}`}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              right: "5vw",
              bottom: '.75vw',
              backgroundColor: "transparent", // Make the background transparent
            }}
          >
            <defs>
              <filter id="drop-shadow">
                <feDropShadow
                  dx="2"
                  dy="6"
                  stdDeviation="6"
                  floodColor="rgba(0, 0, 0, 0.25)"
                />
              </filter>
            </defs>
            <image
              href={frontPost}
              alt="Body"
              width={imageWidthPx}
              height={imageHeightPx}
              style={{
                objectFit: "cover",
                filter: "url(#drop-shadow)", // Apply the drop shadow filter
              }}
            />
            {filteredKeys.map((concern) => (
              <circle
                key={concern.key}
                cx={(concern.height / 100) * imageWidthPx}
                cy={(concern.width / 100) * imageHeightPx}
                r={(0.04 * imageWidthPx) / 2}
                fill={concern.color}
              />
            ))}
          </svg>

          {filteredKeys.length > 1 ? (
            <Row>
              <Col
                className="list"
                style={{
                  position: "relative",

                  left: "7vw",
                  bottom: mq.matches ? "10vw" : "12vw",
                }}
              >
                {filteredKeys.map((k) => (
                  <Col style={{ marginBottom: mq.matches ? null : "-7.5%",  left: "1.75vw", width: "100%" }}>
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontSize: ".8vw",
                        fontWeight: "bold",
                      }}
                    >
                      {k.area}:
                    </Text>
                  </Col>
                ))}
              </Col>
              <Col
                className="list"
                style={{
                  position: "relative",

                  left: "6vw",
                  bottom: mq.matches ? "10vw" : "12vw",

                }}
              >
                {filteredKeys.map((k) => (
                  <Col style={{ marginBottom: mq.matches ? null : "-7.5%", left: "3.25vw" }}>
                  <Text
                      style={{
                        color: Theme.PRIMARY,
                        fontSize: ".8vw",
                        fontWeight: "bold",
                        // fontFamily: "ArialBold",
                        marginLeft: "1vw",
                      }}
                    >
                      {k.type}
                    </Text>
                  </Col>
                ))}
              </Col>
            </Row>
          ) : (
            <Col
            justify="center"
            align="middle"
              style={{
                position: "relative",
                listStyle: "none",
                width: "100%",
                left: "7vw",
                bottom: "9vw",
                alignContent: "center",
              }}
            >
            <div>
                <IoCheckmarkCircleOutline
                  size={"1.5vw"}
                  color={Theme.PRIMARY}
                />
           </div>
              {movements.length === 1 && movements[0] === "Posture" ? (
                <Col>
                  <Text
                    style={{
                      fontSize: ".75vw",
                      fontWeight: "600",
                      left: "1.3vw",
                      position: "relative",
                    }}
                  >
                    Not Applicable
                  </Text>
                  <Col>
                    <Text
                      style={{
                        fontSize: ".9vw",
                        fontWeight: "600",
                        left: "2vw",
                        position: "relative",
                        textAlign: "center",
                      }}
                    >
                      for Posture
                    </Text>
                  </Col>
                </Col>
              ) : (
                <Text style={{ fontSize: ".75vw", fontWeight: "600", color: Theme.TEXT,  }}>
                  No Concerns Measured
                </Text>
              )}
            </Col>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
